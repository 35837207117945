<template>
  <!--    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"-->
  <!--             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"-->
  <!--    >-->
  <div class="app-container">
    <el-dialog :title="!dataForm.id ? '打样单' : '打样单'" :close-on-click-modal="false" :visible.sync="sampleListVisible"
      width="1300px" @close="getClose">
      <div style="border-bottom: 1px solid #cfcfcf; margin-bottom: 20px">
        <span class="one1">打样订单号:{{ dataForm.sampleOrderNo }}</span>
        <span class="one1">打样状态:{{
          dataForm.sampleOrderState === "0"
            ? "未分配"
            : dataForm.sampleOrderState === "1"
              ? "打样中"
              : dataForm.sampleOrderState === "2"
                ? "待验证"
                : dataForm.sampleOrderState === "3"
                  ? "已完成"
                  : dataForm.sampleOrderState === "4"
                    ? "验证驳回"
                    : dataForm.sampleOrderState === "5"
                      ? "待结单"
                      : dataForm.sampleOrderState === "6"
                        ? "待打样"
                        : dataForm.sampleOrderState === "7"
                          ? "待自检"
                          : "暂无"
        }}</span>
        <span class="one1">打样员:{{ dataForm.userName }}</span>
        <span class="one1">实验室:{{ dataForm.laboratory }}</span>
        <span class="one1">分配日期:{{ dataForm.allocationDate }}</span>
        <span class="one1">打样说明:{{ dataForm.description }}</span>
        <span class="one1" v-if="dataForm.sampleOrderState === '4'">驳回理由:{{ dataForm.reason }}</span>
      </div>
      <el-table :data="dataList" border v-loading="dataListLoading" @row-click="singleElection"
        @selection-change="selectionChangeHandle" style="width: 100%" row-key="id" :row-class-name="tableRowClassName"
        :expand-row-keys="getExpandedKeys()" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">

        <el-table-column type="selection" header-align="center" align="center" width="50">
        </el-table-column>

        <el-table-column key="sampleNo" prop="sampleNo" header-align="center" align="center" width="180" label="打样单号">
        </el-table-column>
        <el-table-column header-align="center" align="center" width="70" label="可交付">
          <template slot-scope="scope">
            <p v-if="scope.row.delivery === '1'"><i class="el-icon-medal-1" style="font-size: 28px;"></i></p>
          </template>
        </el-table-column>
        <el-table-column key="sampleNo" prop="mixingPowder" header-align="center" align="center" width="180" label="混合">
        </el-table-column>
        <el-table-column header-align="center" align="center" label="打样品LAB值">
          <template slot-scope="scope">
            <P>L:{{ scope.row.l == 0 ? "无" : scope.row.l }}</P>
            <P>A:{{ scope.row.a == 0 ? "无" : scope.row.a }}</P>
            <P>B:{{ scope.row.b == 0 ? "无" : scope.row.b }}</P>
          </template>
        </el-table-column>
        <el-table-column prop="glossValue" eader-align="center" align="center" label="光泽值">
        </el-table-column>
        <el-table-column prop="glossName" header-align="center" align="center" label="光泽">
        </el-table-column>
        <el-table-column header-align="center" align="center" label="色差值">
          <template slot-scope="scope">
            <P>{{ scope.row.e00Value == -1 ? "无" : scope.row.e00Value }}</P>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="打样状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '0'">打样中</span>
            <span v-if="scope.row.state == '1'">待自检</span>
            <span v-if="scope.row.state == '2'">已自检</span>
            <span v-if="scope.row.state == '3'">待验证</span>
            <span v-if="scope.row.state == '4'">验证驳回</span>
            <span v-if="scope.row.state == '5'">待结单</span>
            <span v-if="scope.row.state == '6'">作废</span>
            <span v-if="scope.row.state == '7'">已完成</span>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" header-align="center" align="center" label="打样开始时间">
        </el-table-column>

        <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="
              recipeListHandle(
                scope.row.id,
                dataForm.cartegryNameS,
                scope.row.sampleType
              )
              ">查看配方</el-button>
            <el-button v-if="dataList.length > 0 &&
              scope.row.state == '3' &&
              isAuth('sample:sampleorder:check')
            " type="text" size="small" @click="
              addOrUpdateHandle(
                scope.row.id,
                scope.row.sampleNo,
                scope.row.sampleOrderNo,
                scope.row.productId,
                scope.row.e00Value
              )
              ">验证</el-button>
            <el-button v-if="dataList.length > 0 &&
              (scope.row.state == '4' ||
                scope.row.state == '5' ||
                scope.row.state == '7') &&
              isAuth('sample:sampleorder:checkdetail')
            " type="text" size="small" @click="
              addProductInfo(
                scope.row.productId,
                scope.row.e00Value,
                scope.row.sampleOrderNo
              )
              ">验证详情</el-button>
            <el-button v-if="dataList.length > 0 &&
              scope.row.state >= '2' &&
              scope.row.state != '6' &&
              isAuth('sample:sampleorder:selfcheckdetail')
            " type="text" size="small" @click="
              addProductInfo(
                scope.row.productId,
                scope.row.e00Value,
                scope.row.sampleOrderNo
              )
              ">自检详情</el-button>
            <el-button v-if="dataList.length > 0 &&
              scope.row.state == '1' &&
              isAuth('sample:sampleorder:selfcheck')
            " type="text" size="small" @click="
              selfInspection(
                scope.row.id,
                scope.row.sampleNo,
                scope.row.sampleOrderNo
              )
              ">自检</el-button>
            <!-- <el-button
              v-if="
                dataList.length > 0 &&
                isAuth('sample:sampleorder:toing') &&
                scope.row.state != '6' &&
                scope.row.sampleType == '0'
              "
              type="text"
              size="small"
              style="color: green"
              @click="saveNewHandle(scope.row.id)"
              >调色</el-button
            > -->
            <el-button v-if="dataList.length > 0 &&
              scope.row.state <= '1' &&
              isAuth('sample:sampleorder:cannel')
            " type="text" size="small" @click="getcancellation(scope.row.id)">作废</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form :inline="true" :model="dataForm" ref="dataForm" size="small">
        <el-form-item>
          <el-button type="danger" @click="deleteHandle" :disabled="dataList.length <= 0" v-if="dataList.length > 0 &&
            (dataForm.sampleOrderState == '4' ||
              dataForm.sampleOrderState == '7') &&
            isAuth('sample:sampleorder:submitTo')
          ">提交验证</el-button>
        </el-form-item>
      </el-form>
      <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
        :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <productupdate v-if="productupdateVisible" ref="productupdate" @refreshDataList="getDataList"></productupdate>
      <recipeList v-if="recipeListVisible" ref="recipeList" @refreshDataList="getDataList"></recipeList>
      <productinfo v-if="productInfoVisible" ref="productinfo" @refreshDataList="getDataList"></productinfo>
      <self-inspection v-if="selfInspectionVisible" ref="selfInspection"
        @refreshDataList="getDataList"></self-inspection>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  del,
  updateState,
  saveNewSample,
  orderAndProductInfo,
  cancellation,
  listAll,
} from "@/api/modules/sampleproduct";
import productupdate from "./productupdate";
import recipeList from "./recipeList";
import productinfo from "./productinfo";
import SelfInspection from "../sampleorder/self-inspection";

export default {
  data() {
    return {
      dataForm: {
        sampleOrderId: "",
        sampleOrderNo: "",
        sampleOrderState: "",
        userName: "",
        laboratory: "",
        allocationDate: "",
        description: "",
        reason: "",
        daterange: [],
        cartegryNameS: "",
      },
      sampleOrderList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      sampleOrderId: "",
      productupdate: false,
      productupdateJD: false,
      sampleListVisible: false,
      recipeListVisible: false,
      productupdateVisible: false,
      productupdateJDVisible: false,
      productInfo: false,
      productInfoVisible: false,
      selfInspectionVisible: false,
      radio: null,
    };
  },
  components: {
    SelfInspection,
    productupdate,
    recipeList,
    productinfo,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    //加颜色标识
    tableRowClassName({ row, state }) {
      if (row.state == 3) {
        return "warning-row";
      }
      return "";
    },
    //关闭回调
    getClose() {
      console.log("111");
      this.$parent.getDataList();
    },
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
      this.getInfoList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },

    init(id, cartegory) {
      this.dataForm.sampleOrderId = id;
      this.sampleListVisible = true;
      this.sampleOrderId = "";
      this.getDataList();
      this.dataForm.cartegryNameS = cartegory;
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        sampleOrderId: this.dataForm.sampleOrderId,
      };
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list;
          this.totalPage = data.page.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
      //通过订单ID找到所匹配的这些值
      let param = {
        sampleOrderId: this.dataForm.sampleOrderId,
      };
      orderAndProductInfo(param).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.sampleOrderNo =
            data.orderAndProductInfoVo.sampleOrderNo;
          this.dataForm.sampleOrderState =
            data.orderAndProductInfoVo.sampleOrderState;
          this.dataForm.userName = data.orderAndProductInfoVo.userName;
          this.dataForm.laboratory = data.orderAndProductInfoVo.laboratory;
          this.dataForm.allocationDate =
            data.orderAndProductInfoVo.allocationDate;
          this.dataForm.description = data.orderAndProductInfoVo.description;
          this.dataForm.reason = data.orderAndProductInfoVo.reason;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
      this.getInfoList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
      this.getInfoList();
    },
    // 多选
    selectionChangeHandle(val) {
      console.log(val);
      this.dataListSelections = val;
    },
    singleElection(row) {
      this.radio = this.dataList.indexOf(row);
      this.sampleOrderId = row.id;
    },
    // 新增 / 修改
    addOrUpdateHandle(id, sampleNo, sampleOrderNo, productId, e00Value) {
      this.productupdateVisible = true;
      this.$nextTick(() => {
        this.$refs.productupdate.init(
          id,
          sampleNo,
          sampleOrderNo,
          productId,
          e00Value
        );
      });
    },
    //配方
    recipeListHandle(id, category, sampleType) {
      this.recipeListVisible = true;
      this.$nextTick(() => {
        this.$refs.recipeList.init(id, category, sampleType);
      });
    },
    //查看商品信息
    addProductInfo(id, e00Value, sampleOrderNo) {
      this.productInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.productinfo.init(id, e00Value, sampleOrderNo);
      });
    },
    //自检
    selfInspection(id, sampleNo, sampleOrderNo) {
      this.selfInspectionVisible = true;
      this.$nextTick(() => {
        this.$refs.selfInspection.init(id, sampleNo, sampleOrderNo);
      });
    },
    // 调色
    saveNewHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
          return item.id;
        });
      this.$confirm(`确定对当前样品调色?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = { id: ids[0] };
        saveNewSample(param).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
    getExpandedKeys() {
      const expandedKeys = this.dataList
        .filter(row => row.delivery === '1')
        .map(row => row.id);
      console.log('Expanded Keys:', expandedKeys);
      return expandedKeys;
    },
    //自检提交
    deleteHandle() {
      //先对datalist进行遍历，找到每条对象的状态是不是等于2，不等于就提示错误
      for (var i = 0; i < this.dataList.length; i++) {
        if (
          this.dataList[i].state != "2" &&
          this.dataList[i].state != "4" &&
          this.dataList[i].state != "6" &&
          this.dataList[i].sampleType != "1"
        ) {
          this.$message.error("请确保每条记录都是已自检过再进行提交");
          this.getDataList();
          return;
        }
        if (this.dataList[i].children) {
          for (var j = 0; j < this.dataList[i].children.length; j++) {
            if (
              !['2', '4', '6'].includes(this.dataList[i].children[j].state) &&
              this.dataList[i].children[j].sampleType != "1"
            ) {
              this.$message.error("请确保每条记录都是已自检过再进行提交");
              this.getDataList();
              return;
            }
          }
        }

      }

      //        if(this.sampleOrderId ==null || this.sampleOrderId==undefined){
      //            this.$message.error('请选择一条打样产品');
      //        }
      var id = null;
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
          return item.id;
        });
      // var ids = [this.sampleOrderId]
      //: this.dataListSelections.map(item => {
      //   return item.id
      // })
      this.$confirm(`确定对此记录进行提交操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updateState(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
                this.dataListLoading = false;
                this.sampleOrderId = "";
              },
            });
          } else {
            this.dataListLoading = false;
            this.$message.error(data.msg);
          }
        });
      });
    },
    //作废
    getcancellation(id) {
      console.log("id" + id);
      let params = {
        id: id,
      };
      this.$confirm(`确定对此条记录进行作废操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancellation(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.one1 {
  display: inline-block;
  margin-right: 35px;
  padding: 0 0 10px 0;
}
</style>
<style>
.el-table .warning-row {
  background: oldlace;
}
</style>
